@import '../../styles/contants.scss';

.container {
  @media screen and (max-width: $md) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  margin-bottom: 2rem;
  // padding: 2rem;
  display: flex;
  justify-content: space-around;

  .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
  }
  .left > :nth-child(1) {
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
  }
  .left > :nth-child(2) {
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
  }
  .left > :nth-child(3) {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .left > img {
    position: absolute;
    width: 10rem;
    bottom: 3rem;
  }

  .right {
    .wrapper {
      width: 30rem;
    }
  }
}
