@import '../../styles/contants.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;

  .wrapper {
    @media screen and (max-width: $sm) {
      grid-template-columns: 1fr;
      text-align: center;
    }
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: flex-end;
    width: 100%;

    .side {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .side > :nth-child(1) {
      font-size: 2rem;
      text-transform: uppercase;
      display: block;
      font-weight: bold;
    }
    .side > :nth-child(2) {
      font-size: 0.8rem;
      text-transform: uppercase;
      display: block;
    }

    img {
      @media screen and (max-width: $sm) {
        width: 80vw;
      }
      width: 25rem;
      justify-self: center;
    }
  }
  .wrapper > :nth-child(3) {
    @media screen and (max-width: $sm) {
      text-align: center;
    }
    text-align: right;
  }

  .reviews {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .testimonials {
    width: 100%;

    .swiperTestimonials {
      padding: 2rem;

      .testimonial {
        display: flex;
        flex-direction: column;
        position: relative;
        background: white;
        border-radius: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
        box-shadow: 0rem 1rem 3rem -50px #7d7d7d;

        img {
          position: absolute;
          width: 3rem;
          top: -1.5rem;
          left: 45%;
        }
        hr {
          height: 1px;
          color: #7d7d7d;
          width: 80%;
          background: rbg(198, 198, 198);
          //   border: none ;
        }
      }
      .testimonial > :nth-child(2) {
        align-items: center;
        font-size: 0.8rem;
        letter-spacing: 1px;
        margin-top: 2rem;
      }
      .testimonial > :nth-child(4) {
        font-weight: 500;
      }
    }
  }
}
