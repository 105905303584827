@import '../../styles/contants.scss';

.container {
  width: 100%;
  margin-top: 5rem;

  hr {
    width: 100%;
    height: 1px;
    // border: none;
    color: white;
    margin-top: 1rem;
  }
  .wrapper {
    @media screen and (max-width: $md) {
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      font-size: 1.7rem;
      gap: 2rem;
      text-align: justify;
    }

    display: flex;
    width: 100%;
    justify-content: space-around;

    .logo {
      @media screen and (max-width: $md) {
        display: none;
      }
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      img {
        width: 2.5rem;
        height: 2.5rem;
      }
      span {
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 12rem;

      .detail {
        display: flex;
        flex-direction: column;
        width: inherit;
        font-size: 0.7rem;
        font-style: italic;

        .pngLine:hover {
          cursor: pointer;
        }

        .pngLine {
          display: flex;
          gap: 1rem;
          align-items: center;

          .icon {
            width: 25px;
          }
        }
      }
      .detail > :nth-child(1) {
        // background: white;
        font-weight: 600;
        font-style: normal;
        font-size: 1.2rem;
      }
    }
  }

  .copyRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 0.65rem;
    margin-top: 2rem;
  }
}
