@import '../../styles/contants.scss';

.container {
  @media screen and (max-width: $md) {
    gap: 0.5rem;
  }
  padding: 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  .featureImg {
    @media screen and (max-width: $md) {
      display: none;
    }
  }

  h1{
    @media screen and (max-width: $sm) {
      text-align: center;
      font-size: 1.5rem;
    }
  }

  .products {
    @media screen and (max-width: $md) {
      grid-template-columns: none;
    }
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;

    .menu {
      @media screen and (max-width: $md) {
        flex-direction: row;
      }
      @media screen and (max-width: $sm) {
        margin-left: -2rem;
        gap: 0.5rem;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1rem;
      }
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      font-weight: 500;
      font-size: 1.3rem;
    }
    .menu > :hover {
      color: var(--pink);
      cursor: pointer;
    }

    .list {
      //   background: whitesmoke;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      height: 25rem;
      overflow-y: scroll;
      grid-gap: 2rem;
      justify-content: space-between;

      .product {
        width: 12rem;
        height: 8rem;
        background: white;
        position: relative;
        overflow: hidden;
        padding: 1rem;
        display: flex;
        border-radius: 1rem;

        img {
          top: 3rem;
          width: 6rem;
          height: 11rem;
        }
      }
    }
  }
}
.container > :nth-child(1) {
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}
