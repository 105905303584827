@import '../../styles/contants.scss';

.container {
  // position: fixed;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bars {
    @media screen and (max-width: $sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    display: none;
    width: 3rem;
    height: 3rem;
    margin-right: 20%;
    cursor: pointer;

    .barIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .logo > img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  .logo > span {
    font-weight: 600;
    width: 8.5rem;
  }

  .menuContainer {
    @media screen and (max-width: $sm) {
      position: absolute;
      top: 3rem;
      left: 2.5rem;
      right: 0rem;
      width: 8rem;
    }
    display: flex;
    margin-right: 2%;
    .menu {
      @media screen and (max-width: $sm) {
        background: white;

        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        margin-left: -2rem;
        gap: 2rem;
        font-weight: 500;
        list-style: none;
      }
      display: flex;
      gap: 2rem;
      font-weight: 500;
      list-style: none;
    }

    .menu > li:hover {
      color: #fe956f;
      cursor: pointer;
    }
  }

  .search {
    @media screen and (max-width: $md) {
      display: none;
    }
    width: 7rem;
    height: 30%;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;

    margin-right: 2%;
    // gap: 2rem;
  }
  .cart {
    width: 1.5rem;
    height: 1.5rem;
  }
}
